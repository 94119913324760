import styled from 'styled-components';
import {useNavigate} from "react-router";

function NotFound(props) {

    const navigate = useNavigate();

    return (
        <Wrapper>

            <Container>
                <picture>
                    <source srcSet={'https://wheelchairkorea.cdn.ntruss.com/file/static/img/404_desktop.webp'}
                            type={'image/webp'} />
                    <img
                        alt={'404에러 로고'}
                        src={'https://wheelchairkorea.cdn.ntruss.com/file/static/img/404_desktop.png'}/>
                </picture>
                <Title>
                    404 ERROR
                </Title>
                <SubTitle>
                    페이지가 존재하지 않거나, 사용할수 없는 페이지입니다.<br/>
                    입력하신 주소가 정확한지 다시 한 번 확인해주세요.
                </SubTitle>
                <ButtonWrap>
                    <button onClick={() => navigate('/')}>메인으로</button>
                </ButtonWrap>
            </Container>

        </Wrapper>
    );
}

export default NotFound;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 14rem 0;
`


const Container = styled.div`
  width: 89.6rem;
  height: 54.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.8rem 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  img {
    width: 19.5rem;
    height: 19.5rem;
    margin-bottom: 2rem;
    
  }
`

const Title = styled.h1`
  margin-bottom: 3.5rem;
  text-align: center;
  font-size: 3rem;
`

const SubTitle = styled.h2`
  font-size: 2.4rem;
  text-align: center;
  line-height: 1.5;
`

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 6rem;

  > button {
    ${({theme}) => theme.button.type1};
    width: 30.2rem;
    height: 5.9rem;
    font-size: 2.8rem;
    font-weight: bold;

  }
`
