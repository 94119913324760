import {css} from 'styled-components';

const theme = {
    mainColor: css`
      color: #95b134;
    `,
    mainBackgroundColor: css`
      background-color: #95b134;
    `,
    button: {
        type1: css`
          background-color: #95b134;
          border: #95b134 0.3rem solid;
          color: #FFFFFF;
        `,
        type2: css`
          background: #D4E0AB;
          border: #D4E0AB 0.3rem solid;
          color: #FFFFFF;
        `,
        type3: css`
          background: #FFFFFF;
          border: #95b134 0.3rem solid;
          color: #95b134;
        `,
        type4: css`
          background: #FFFFFF;
          border: #FFFFFF 0.3rem solid;
          color: #95b134;
        `,
        type5: css`
          background: #bbbbbb;
          border: #bbbbbb 0.05rem solid;
          color: #fff;
        `,
        type6: css`
          background:#73a7b7;
          border: #73a7b7 0.05rem solid;
          color: #fff;
        `
    },

    input: {
        type1: css`
          border: #BBBBBB 0.05rem solid;

          &::placeholder {
            color: #BBBBBB;
          }
        ;
        `,
        type2: css`
          border: #95b134 0.15rem solid;
          box-shadow: 0 0 0.6rem 0 rgba(149, 177, 52, 0.25);

          &::placeholder {
            color: #BBBBBB;
          }
        `,
        type3: css`
          border: #FF0000 0.15rem solid;
          box-shadow: 0 0 0.6rem rgb(255, 0, 0, 0.25);
        `
    },

    allOfCenter: css`
      display: flex;
      justify-content: center;
      align-items: center;
    `,

    check: css`
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      border: #bbb 0.1rem solid;
      border-radius: 0.2rem;
      cursor: pointer;

      :checked {
        display: inline-block;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border:  #95b134 0.1rem solid;
        border-radius: 0.2rem;
      }

      :checked::after {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 1.5rem;
        height: 1.5rem;
        content: "✔";
        text-align: center;
        border-radius: 0.2rem;
        background:  #95b134;
        color: white;
        font-size: 1rem;
      }
    `,

    radio:css`
      label{
        width:100%;
        height: 4rem;
        display: flex;
        align-items:center;
        padding: 0 2rem;
        cursor:pointer;
        font-size: 1.8rem;
        
        :hover{
          background-color: #D4E0AB;
        }
      }

      input[type='radio']{
        position: relative;
        vertical-align: middle;
        appearance: none;
        border: solid 0.1rem #bbb;
        background-color: #fff;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 1.5rem;

        :checked{
          ::after{
            position: absolute;
            content: '';
            width: 1.2rem;
            height: 1.2rem;
            top: 18%;
            left: 17%;
            background-color: #95b134;
            border-radius: 50%;
          }
        }
      }
    `

};


export default theme;