import {BrowserRouter, Routes, Route} from "react-router-dom";
import React, {Suspense, lazy} from "react";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import PrivateRoute from "./services/PrivateRoute";
import NotFound from "./components/NotFound";

function App() {

    const Login = lazy(() => import('./pages/Login/Login'));
    const AllOfSignUpUser = lazy(()=>import('./pages/SignUpUser/AllOfSignUpUser/AllOfSignUpUser'))
    const UserInfoPage = lazy(()=>import('./pages/SignUpUser/AllOfSignUpUser/UserInfoPage'))
    const ClaimInsuranceList = lazy(()=>import('./pages/BoardManage/ClaimInsurance/ClaimInsuranceList'));
    const ClaimInsurancePage = lazy(()=>import('./pages/BoardManage/ClaimInsurance/ClaimInsurancePage'));
    const FaqList = lazy(()=>import('./pages/BoardManage/Faq/FaqList'));
    const FaqEdit = lazy(()=>import('./pages/BoardManage/Faq/FaqEdit'));
    const NoticeEdit = lazy(()=>import('./pages/BoardManage/Notice/NoticeEdit'));
    const NoticeList = lazy(()=>import('./pages/BoardManage/Notice/NoticeList'));
    const PaymentCaseList = lazy(()=>import('./pages/BoardManage/PaymentCase/PaymentCaseList'));
    const PaymentCaseEdit = lazy(()=>import('./pages/BoardManage/PaymentCase/PaymentCaseEdit'));
    const ReceiptOfChangeList = lazy(()=>import('./pages/BoardManage/ReceiptOfChange/ReceiptOfChangeList'));
    const ReceiptOfChangePage = lazy(()=>import('./pages/BoardManage/ReceiptOfChange/ReceiptOfChangePage'));
    const GroupSignUpList = lazy(()=>import('./pages/SignUpInsurance/GroupSignUp/GroupSignUpList'));
    const GroupSignUpPage = lazy(()=>import('./pages/SignUpInsurance/GroupSignUp/GroupSignUpPage'));
    const AllOfJoinInsuranceList = lazy(()=>import('./pages/SignUpInsurance/AllOfJoinInsurance/AllOfJoinInsuranceList'));
    const AllOfJoinInsurancePage = lazy(()=>import('./pages/SignUpInsurance/AllOfJoinInsurance/AllOfJoinInsurancePage'));
    const TravelJoinList = lazy(()=>import('./pages/SignUpInsurance/TravelJoin/TravelJoinList'));
    const TravelJoinPage = lazy(()=>import('./pages/SignUpInsurance/TravelJoin/TravelJoinPage'));
    const TravelJoinCancelList = lazy(()=>import('./pages/SignUpInsurance/TravelJoinCancel/TravelJoinCancelList'));
    const TravelJoinCancelPage = lazy(()=>import('./pages/SignUpInsurance/TravelJoinCancel/TravelJoinCancelPage'));
    const ReportManageList = lazy(()=>import('./pages/UsedDeal/ReportManage/ReportManageList'));
    const UsedDealGoodsList = lazy(()=>import('./pages/UsedDeal/UsedDealGoodsList/UsedDealGoodsList'));
    const ReportManagePage = lazy(()=>import('./pages/UsedDeal/ReportManage/ReportManagePage'));
    const InquiryList = lazy(()=>import('./pages/BoardManage/Inquiry/InquiryList'));
    const InquiryPage = lazy(()=>import('./pages/BoardManage/Inquiry/InquiryPage'))
    const UsedDealGoodsPage = lazy(()=>import('./pages/UsedDeal/UsedDealGoodsList/UsedDealGoodsPage'));
    const TravelNoticeEdit = lazy(()=>import('./pages/BoardManage/TravelNotice/TravelNoticeEdit'));
    const TravelNoticeList = lazy(()=>import('./pages/BoardManage/TravelNotice/TravelNoticeList'));
    const TravelFaqList = lazy(()=>import('./pages/BoardManage/TravelFaq/TravelFaqList'));
    const TravelFaqEdit = lazy(()=>import('./pages/BoardManage/TravelFaq/TravelFaqEdit'));
    const TravelPurposeList = lazy(()=>import('./pages/BoardManage/TravelPurpose/TravelPurposeList'));
    const TravelPurposeEdit = lazy(()=>import('./pages/BoardManage/TravelPurpose/TravelPurposeEdit'));
    const GuaranteeTypeList = lazy(()=>import('./pages/BoardManage/GuaranteeType/GuaranteeTypeList'));
    const GuaranteeTypeEdit = lazy(()=>import('./pages/BoardManage/GuaranteeType/GuaranteeTypeEdit'));
    const GuaranteeAmountList = lazy(()=>import('./pages/BoardManage/GuaranteeAmount/GuaranteeAmountList'));
    const GuaranteeAmountEdit = lazy(()=>import('./pages/BoardManage/GuaranteeAmount/GuaranteeAmountEdit'));
    const TravelKrPriceList = lazy(()=>import('./pages/BoardManage/TravelPrice/TravelKrPriceList'));
    const TravelFsPriceList = lazy(()=>import('./pages/BoardManage/TravelPrice/TravelFsPriceList'));
    const TravelPlanList = lazy(()=>import('./pages/BoardManage/TravelPlan/TravelPlanList'));
    const TravelPlanEdit = lazy(()=>import('./pages/BoardManage/TravelPlan/TravelPlanEdit'));
    const DeviceCompanyList = lazy(()=>import('./pages/BoardManage/DeviceCompany/DeviceCompanyList'));
    const DeviceCompanyEdit = lazy(()=>import('./pages/BoardManage/DeviceCompany/DeviceCompanyEdit'));
    const DeviceModelList = lazy(()=>import('./pages/BoardManage/DeviceModel/DeviceModelList'));
    const DeviceModelEdit = lazy(()=>import('./pages/BoardManage/DeviceModel/DeviceModelEdit'));

    return (
        <BrowserRouter>
            <Suspense fallback={''}>
                {/*{window.location.pathname !== '/' &&*/}
                {/*    <Header/>*/}
                {/*}*/}
                {/*{window.location.pathname !== '/' &&*/}
                {/*    <SideBar/>*/}
                {/*}*/}
                <Routes>
                    <Route path={'/'} element={<Login/>}/>
                    <Route path={'*'} element={<NotFound/>}/>

                    <Route element={<PrivateRoute authenticated={true}/> }>
                        <Route path={'/allofsignupuser'} element={<AllOfSignUpUser />}/>
                        <Route path={'/allofsignupusers'} element={<UserInfoPage />}/>
                        <Route path={'/claiminsuranceinfo'} element={<ClaimInsuranceList />}/>
                        <Route path={'/claiminsuranceinfo/:id'} element={<ClaimInsurancePage />}/>
                        <Route path={'/faq'} element={<FaqList />} />
                        <Route path={'/faq/:id'} element={<FaqEdit />} />
                        <Route path={'/faq/write'} element={<FaqEdit />} />
                        <Route path={'/notice'} element={<NoticeList />}/>
                        <Route path={'/notice/:id'} element={<NoticeEdit />}/>
                        <Route path={'/notice/write'} element={<NoticeEdit />}/>
                        <Route path={'/paymentcase'} element={<PaymentCaseList />}/>
                        <Route path={'/paymentcase/:id'} element={<PaymentCaseEdit />}/>
                        <Route path={'/paymentcase/write'} element={<PaymentCaseEdit />}/>
                        <Route path={'/receiptofchange'} element={<ReceiptOfChangeList />}/>
                        <Route path={'/receiptofchange/:id'} element={<ReceiptOfChangePage />}/>
                        <Route path={'/groupsignup'} element={<GroupSignUpList />}/>
                        <Route path={'/groupsignup/:id'} element={<GroupSignUpPage />}/>
                        <Route path={'/allofjoininsurance'} element={<AllOfJoinInsuranceList/>}/>
                        <Route path={'/allofjoininsurance/:id'} element={<AllOfJoinInsurancePage/>}/>
                        <Route path={'/traveljoin'} element={<TravelJoinList/>}/>
                        <Route path={'/traveljoin/:id'} element={<TravelJoinPage/>}/>
                        <Route path={'/travelcanceljoin'} element={<TravelJoinCancelList/>}/>
                        <Route path={'/travelcanceljoin/:id'} element={<TravelJoinCancelPage/>}/>
                        <Route path={'/reportmanage'} element={<ReportManageList/>}/>
                        <Route path={'/reportmanage/:id'} element={<ReportManagePage/>}/>
                        <Route path={'/inquiry'} element={<InquiryList/>}/>
                        <Route path={'/inquiry/:id'} element={<InquiryPage/>}/>
                        <Route path={'/useddealmanage'} element={<UsedDealGoodsList/>}/>
                        <Route path={'/useddealmanage/:id'} element={<UsedDealGoodsPage />} />
                        <Route path={'/travelnotice'} element={<TravelNoticeList />}/>
                        <Route path={'/travelnotice/:id'} element={<TravelNoticeEdit />}/>
                        <Route path={'/travelnotice/write'} element={<TravelNoticeEdit />}/>
                        <Route path={'/travelfaq'} element={<TravelFaqList />}/>
                        <Route path={'/travelfaq/:id'} element={<TravelFaqEdit />} />
                        <Route path={'/travelfaq/write'} element={<TravelFaqEdit />} />
                        <Route path={'/travelpurpose'} element={<TravelPurposeList />}/>
                        <Route path={'/travelpurpose/:id'} element={<TravelPurposeEdit />} />
                        <Route path={'/travelpurpose/write'} element={<TravelPurposeEdit />} />
                        <Route path={'/guaranteetype'} element={<GuaranteeTypeList />}/>
                        <Route path={'/guaranteetype/:id'} element={<GuaranteeTypeEdit />} />
                        <Route path={'/guaranteetype/write'} element={<GuaranteeTypeEdit />} />
                        <Route path={'/guaranteeamount'} element={<GuaranteeAmountList />}/>
                        <Route path={'/guaranteeamount/:id'} element={<GuaranteeAmountEdit />} />
                        <Route path={'/guaranteeamount/write'} element={<GuaranteeAmountEdit />} />
                        <Route path={'/travelkrprice'} element={<TravelKrPriceList />}/>
                        <Route path={'/travelfsprice'} element={<TravelFsPriceList />}/>
                        <Route path={'/travelplan'} element={<TravelPlanList />}/>
                        <Route path={'/travelplan/:id'} element={<TravelPlanEdit />} />
                        <Route path={'/travelplan/write'} element={<TravelPlanEdit />} />
                        <Route path={'/devicecompany'} element={<DeviceCompanyList/>}/>
                        <Route path={'/devicecompany/:id'} element={<DeviceCompanyEdit/>}/>
                        <Route path={'/devicecompany/write'} element={<DeviceCompanyEdit/>}/>
                        <Route path={'/devicemodel'} element={<DeviceModelList/>}/>
                        <Route path={'/devicemodel/:id'} element={<DeviceModelEdit/>}/>
                        <Route path={'/devicemodel/write'} element={<DeviceModelEdit/>}/>

                    </Route>

                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
