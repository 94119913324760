import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";


const {persistAtom} = recoilPersist({
    key:'recoil-persist',
    storage: localStorage,
});

export const currentUrl = atom({
    key:'currentUrl',
    default:'/allofsignupuser',
    effects_UNSTABLE : [persistAtom]
})

export const handleSidebar = atom({
    key: 'handleSideBar',
    default: true,
    effects_UNSTABLE:[persistAtom]
})