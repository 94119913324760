import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const global = createGlobalStyle`
  ${reset};


  * {
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
    margin:0;
    padding: 0;

  }

  html{
    font-size:62.5%;
  }
  
 body{
   overflow-x: hidden;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';

  }

#root{
  display:flex;
  flex-direction:column;
  min-height: 100vh;

}

  input,
  button{
    border:none;
    border-radius:0.6rem;
    outline: none;
  }

  button {
    background: transparent;
    cursor:pointer;
    text-align: center;
  }

  input:focus,
  input:active,
  button:focus,
  button:active {
    outline: none;
  }
  
  select{
    outline:none;
  }

  img{
    -webkit-user-drag: none;
  }

`;

export default global;
