import React, {useEffect, useRef} from 'react';
import styled, {keyframes} from 'styled-components';
import {menuData} from "../model/menu";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {currentUrl, handleSidebar} from "../recoil/recoil";

function SideBar() {

    const navigate = useNavigate();

    const [currentHref, setCurrentHref] = useRecoilState(currentUrl)
    const [searchParams, setSearchParams] = useSearchParams();



    return (
        <Wrapper>
            <Container>
                <picture>
                    <source srcSet={'https://wheelchairkorea.cdn.ntruss.com/file/static/press/renewal_nav_logo.webp'}
                            type="image/webp"/>
                    <Logo src={'https://wheelchairkorea.cdn.ntruss.com/file/static/img/renewal_nav_logo.jpg'} alt={'펫핀스로고'} />
                </picture>
                {menuData?.map((menus, i) => {
                    return (
                        <MenuContainer key={i}>
                            <Menu>{menus.menu}</Menu>
                            {menus?.subMenu?.map((sub, i) => {
                                return (
                                    <SubMenu
                                        key={i}
                                        onClick={() => {
                                            setCurrentHref(sub.href);
                                            navigate(sub.href)

                                            // setShowSideBar(!showSideBar)
                                        }}
                                        $activate={(window.location.pathname).includes(sub.href2)}
                                    >
                                        {sub.icon()}
                                        <li>{sub.title}</li>
                                    </SubMenu>
                                )
                            })}

                        </MenuContainer>
                    )
                })}
                <Logout onClick={()=>{
                    localStorage.clear();
                    navigate('/')
                }}>
                   <button>로그아웃</button>
                </Logout>
            </Container>
        </Wrapper>
    );
}

export default SideBar;

// const test = keyframes`
//   0% {
//     transform: translateX(0px); /* 애니메이션이 0%만큼 동작시 */
//     // 몇 줄을 넣어도 상관없다!!
//   }
//   100% {
//     transform: translateX(20px); /* 애니메이션이 100%만큼 동작시 */
//   }
// `

// props에 따라 쓸 때 함수로 빼서 사용
// 안 쓸 때는 ${}로만 넣어서 씀


const Wrapper = styled.div`
  width: 27rem;
  background-color: #FFFFFF;
  padding: 11rem 2rem 4rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
height:100vh;
  overflow-y:scroll;
  
  &::-webkit-scrollbar {
    display: none;
  }
`



const Container = styled.div`
  width: 100%;
  min-width: 19.5rem;
  overflow-y:scroll;
  
  &::-webkit-scrollbar {
    display: none;
  }
  picture{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 27rem;
    height: 11rem;
    background-color: #FFFFFF;

  }
`

const MenuContainer = styled.ul`
  margin-bottom: 3rem;
  transition: 0.2s all;

  ul:hover {
    transition: 0.3s all;
    background-color: #DFE1E3;
    border-radius: 1rem;
    font-weight: bold;
  }
`

const Menu = styled.li`
  margin-bottom: 1.8rem;
  color: #939393;
  font-size: 1.4rem;
  padding: 0 1.5rem;
`

const SubMenu = styled.ul`
  font-size: 1.4rem;
  cursor: pointer;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  padding: 1.1rem 1.5rem;
    // background-color: ${props => props.$activate ? '#DFE1E3' : '#F8F9FA'};
  background-color: ${props => props.$activate ? '#DFE1E3' : 'white'};
  border-radius: ${props => props.$activate? '1rem' : '0'};
  font-weight: ${props => props.$activate ? 'bold' : 'normal'};
  transition: 0.3s all;

  li {
    margin-left: 1rem;
  }
`


const Logo = styled.img`
  width: 18.4rem;
  height: 5rem;
  cursor: pointer;
  margin:2rem 0 3rem 0;
`;


const Logout = styled.div`
  display: flex;
  justify-content: center;
  
  button{
    ${({theme})=>theme.button.type5};
    display: flex;
    justify-content: center;
    font-size:1.6rem;
    padding: 1rem;
    cursor:pointer;
    font-weight: bold;
  }

`