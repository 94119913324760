import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from "./styles/GlobalStyle";
import {ThemeProvider} from 'styled-components';
import theme from './styles/theme';
import axios from "axios";
import './index.css';
import {RecoilRoot} from "recoil";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <GlobalStyle/>
        <ThemeProvider theme={theme}>
            <RecoilRoot>
                <App/>
            </RecoilRoot>
        </ThemeProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
